import React from "react";
import {
  Container,
  Row,
  Col
} from "reactstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import Typewriter from 'typewriter-effect';
import { scrollElementIntoView } from "../helper";

function SectionHeader(props) {
  const intl = props.intl;
  const headline1 = intl.formatMessage({ id: 'headline1' });
  const headline2 = intl.formatMessage({ id: 'headline2' });
  const headline3 = intl.formatMessage({ id: 'headline3' });
  const headline4 = intl.formatMessage({ id: 'headline4' });

  return (
    <div className="section header">
      <Container>
        <Row>
          <Col lg="12">
            <div className="page-header">
              <div className="content-center">
                <h1 className="title">
                  <FormattedMessage id="headline" />
                  <Typewriter
                    options={{
                      strings: [headline1, headline2, headline3, headline4],
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </h1>
                <a href="#services"
                   onClick={e => {
                     e.preventDefault();
                     scrollElementIntoView(document.getElementById('services'));
                   }}>
                  <FormattedMessage id="learn_more" />
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default injectIntl(SectionHeader);
