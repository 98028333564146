import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";
import { FormattedMessage, injectIntl } from "react-intl";

function SectionServices(props) {
  const intl = props.intl;
  const service1_title = intl.formatMessage({ id: 'service1_title' });
  const service2_title = intl.formatMessage({ id: 'service2_title' });
  const service3_title = intl.formatMessage({ id: 'service3_title' });
  const service4_title = intl.formatMessage({ id: 'service4_title' });

  return (
    <div className="section section-dark cd-section" id="services">
      <Container>
        <Row>
          <Col md="12">
            <h3 className="title"><FormattedMessage id="services_title" /></h3>
            <Row>
              <Col lg="6">
                <h2 className="description">
                  <FormattedMessage id="services_description" />
                </h2>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="service-row">
          <Col lg="8" md="6">
            <Row>
              <Col lg="6" md="12">
                <Card className="icon step-1">
                  <div className="card-image">
                    <img alt={service1_title} src={require("../../assets/icons/emojis/kompass.png")} />
                  </div>
                  <CardBody>
                    <CardTitle tag="h3"><FormattedMessage id="service1_title" /></CardTitle>
                    <p className="card-description">
                      <FormattedMessage id="service1_description" />
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" md="12">
                <Card className="icon step-2">
                  <div className="card-image">
                    <img alt={service2_title} src={require("../../assets/icons/emojis/desktopcomputer.png")} />
                  </div>
                  <CardBody>
                    <CardTitle tag="h3"><FormattedMessage id="service2_title" /></CardTitle>
                    <p className="card-description">
                      <FormattedMessage id="service2_description" />
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="d-md-none d-lg-flex">
              <Col lg="6" md="12">
                <Card className="icon step-1">
                  <div className="card-image">
                    <img alt={service3_title} src={require("../../assets/icons/emojis/technologin.png")} />
                  </div>
                  <CardBody>
                    <CardTitle tag="h3"><FormattedMessage id="service3_title" /></CardTitle>
                    <p className="card-description">
                      <FormattedMessage id="service3_description" />
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" md="12">
                <Card className="icon step-2">
                  <div className="card-image">
                    <img alt={service4_title} src={require("../../assets/icons/emojis/einkaufswagen.png")} />
                  </div>
                  <CardBody>
                    <CardTitle tag="h3"><FormattedMessage id="service4_title" /></CardTitle>
                    <p className="card-description">
                      <FormattedMessage id="service4_description" />
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col lg="4" md="6">
            <Card className="large">
              <div className="overlay">
                <CardBody>
                  <CardTitle tag="h3"><FormattedMessage id="service_large" /></CardTitle>
                </CardBody>
              </div>
            </Card>
          </Col>
        </Row>
        <Row className="d-none d-md-flex d-lg-none">
          <Col md="6">
            <Card className="icon step-1">
              <div className="card-image">
                <img alt={service3_title} src={require("../../assets/icons/building_websites.svg")} />
              </div>
              <CardBody>
                <CardTitle tag="h3"><FormattedMessage id="service3_title" /></CardTitle>
                <p className="card-description">
                  <FormattedMessage id="service3_description" />
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
            <Card className="icon step-2">
              <div className="card-image">
                <img alt={service4_title} src={require("../../assets/icons/shopping_app.svg")} />
              </div>
              <CardBody>
                <CardTitle tag="h3"><FormattedMessage id="service4_title" /></CardTitle>
                <p className="card-description">
                  <FormattedMessage id="service4_description" />
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default injectIntl(SectionServices);
