import React from "react";
import {
  Container,
  Row,
  Col
} from "reactstrap";
import { FormattedMessage } from "react-intl";

function SectionContact(props) {
  return (
    <div className="section cd-section" id="contact">
      <Container>
        <Row>
          <Col lg="12">
            <div className="contact-container">
              <h2 className="description contact">
                <FormattedMessage id="contact_description" />
              </h2>
              <a className="create-contact dark no-top" href="mailto:hey@newww.agency?subject=Kontaktanfrage"><FormattedMessage id="create_contact" /></a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SectionContact;
