import React from "react";
import {
  Card,
  Container,
  Row,
  Col
} from "reactstrap";
import { FormattedMessage } from "react-intl";
import AboutImage from "../aboutimage";

function SectionAbout() {
  return (
    <div className="section section-dark cd-section" id="about">
      <Container>
        <Row>
          <Col md="12">
            <h3 className="title"><FormattedMessage id="about_title" /></h3>
            <Row>
              <Col lg="6">
                <h2 className="description">
                  <FormattedMessage id="about_description" />
                </h2>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg="4" md="6">
            <Card className="about">
              <div className="card-image card-image">
                <AboutImage
                  alt="Vincent Abel"
                  src="vincent_abel.jpg"
                />
                <div className="overlay">
                  <div className="about-container">
                    <h3>Vincent Abel</h3>
                    <p>Managing Partner</p>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SectionAbout;
