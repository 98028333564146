import React, {useState} from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";
import { FormattedMessage } from "react-intl";
import ProjectImage from "../projectimage";
import {Collapse} from 'react-collapse';
import {scrollElementIntoView} from "../helper";

function ShowMoreButton(props) {
  const opened = props.opened;
  if (opened) {
    return <button onClick={props.onClick} className="show-more"><FormattedMessage id="show_less" /></button>;
  }
  return <button onClick={props.onClick} className="show-more"><FormattedMessage id="show_more" /></button>;
}

function SectionWork() {
  const [opened, setOpened] = useState(false);
  return (
    <div className="section cd-section" id="work">
      <Container>
        <Row>
          <Col md="12">
            <h3 className="title"><FormattedMessage id="work_title" /></h3>
            <Row>
              <Col lg="6">
                <h2 className="description">
                  <FormattedMessage id="work_description" />
                </h2>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="work-tiles">
          <Col lg="6">
            <Card className="plain">
              <div className="card-image">
                <a href="https://www.hellmann.com/" target="_blank" rel="noopener noreferrer">
                  <ProjectImage
                      alt="Hellmann Logistics"
                      src="hellmann-compressor.png"
                  />
                </a>
              </div>
              <CardBody>
                <CardTitle tag="h3">Hellmann Logistics</CardTitle>
                <p className="card-description">
                  <FormattedMessage id="project_hellmann_description" />
                </p>
                <a className="card-coming-soon" href="https://www.hellmann.com/" target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="to_the_page" />
                </a>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6">
            <Card className="plain">
              <div className="card-image">
                <a href="https://kickasssports.de/" target="_blank" rel="noopener noreferrer">
                  <ProjectImage
                      alt="KickAss Sports"
                      src="kickass-sports-compressor.png"
                  />
                </a>
              </div>
              <CardBody>
                <CardTitle tag="h3">KickAss Sports</CardTitle>
                <p className="card-description">
                  <FormattedMessage id="project_kickass_description" />
                </p>
                <a className="card-coming-soon" href="https://kickasssports.de/" target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="to_the_page" />
                </a>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="work-tiles" id="last-visible-tile">
          <Col lg="6">
            <Card className="plain">
              <div className="card-image">
                <a href="https://mysourcenutrition.com/" target="_blank" rel="noopener noreferrer">
                  <ProjectImage
                      alt="My Source Nutrition"
                      src="screenshot-my-source-nutrition.png"
                  />
                </a>
              </div>
              <CardBody>
                <CardTitle tag="h3">My Source Nutrition</CardTitle>
                <p className="card-description">
                  <FormattedMessage id="project_my_source_nutrition_description" />
                </p>
                <a className="card-coming-soon" href="https://mysourcenutrition.com/" target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="to_the_page" />
                </a>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6">
            <Card className="plain">
              <div className="card-image">
                <ProjectImage
                    alt="Philipp Seipp"
                    src="screenshot-philipp-seipp.png"
                />
              </div>
              <CardBody>
                <CardTitle tag="h3">Philipp Seipp</CardTitle>
                <p className="card-description">
                  <FormattedMessage id="project_philipp_seipp_description" />
                </p>
                <p className="card-coming-soon">
                  Coming Soon
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Collapse isOpened={opened}>
          <Row className="work-tiles">
            <Col lg="6">
              <Card className="plain">
                <div className="card-image">
                  <a href="https://antje-abel-architekten.de" target="_blank" rel="noopener noreferrer">
                    <ProjectImage
                        alt="Antje Abel Architekten"
                        src="screenshot-antje-abel-architekten.png"
                    />
                  </a>
                </div>
                <CardBody>
                  <CardTitle tag="h3">Antje Abel Architekten</CardTitle>
                  <p className="card-description">
                    <FormattedMessage id="project_ama_architekten_description" />
                  </p>
                  <a className="card-coming-soon" href="https://antje-abel-architekten.de" target="_blank" rel="noopener noreferrer">
                    <FormattedMessage id="to_the_page" />
                  </a>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6">
              <Card className="plain">
                <div className="card-image">
                  <ProjectImage
                      alt="Baubranche-Jobs.de"
                      src="screenshot-baubranche-jobs-de.png"
                  />
                </div>
                <CardBody>
                  <CardTitle tag="h3">Baubranche-Jobs.de</CardTitle>
                  <p className="card-description">
                    <FormattedMessage id="project_baubranche_jobs_description" />
                  </p>
                  <p className="card-coming-soon">
                    Coming Soon
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="work-tiles">
            <Col lg="6">
              <Card className="plain">
                <div className="card-image">
                  <ProjectImage
                      alt="Steele Meals"
                      src="screenshot-steele-meals.png"
                  />
                </div>
                <CardBody>
                  <CardTitle tag="h3">Steele Meals</CardTitle>
                  <p className="card-description">
                    <FormattedMessage id="project_steele_meals_description" />
                  </p>
                  <p className="card-coming-soon">
                    Coming Soon
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6">
              <Card className="plain">
                <div className="card-image">
                  <ProjectImage
                      alt="YourProject"
                      src="screenshot-yourproject.png"
                  />
                </div>
                <CardBody>
                  <CardTitle tag="h3">YourProject</CardTitle>
                  <p className="card-description">
                    <FormattedMessage id="project_yourproject_description" />
                  </p>
                  <p className="card-coming-soon">
                    Coming Soon
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Collapse>
        <ShowMoreButton opened={opened} onClick={() => {
          setOpened(!opened);
          if (opened === true) {
            scrollElementIntoView(document.getElementById('last-visible-tile'));
          }
        }} />
      </Container>
    </div>
  );
}

export default SectionWork;
