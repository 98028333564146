import safeGet from "lodash.get";
import React, { useMemo } from "react";
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby";

function AboutImage({src, alt}) {

  const data = useStaticQuery(graphql`
      query {
          allFile( filter: { internal: { mediaType: { regex: "/image/" } } } ) {
              nodes {
                  relativePath
                  childImageSharp {
                      fluid(quality: 70, maxWidth: 350) {
                          ...GatsbyImageSharpFluid_withWebp
                      }
                  }
              }
          }
      }
  `);

  const match = useMemo(() => (
    data.allFile.nodes.find(({ relativePath }) => src === relativePath)
  ), [ data, src ]);

  const fluid = safeGet(match, 'childImageSharp.fluid');

  return (
    <Image alt={alt} className="img" fluid={fluid}></Image>
  );
}

export default AboutImage;
